<template>
  <div class="home share-bg">
    <!-- 顶部模板一 -->
    <!-- <div class="banner" v-if="type === 1">
      <SwiperBanner :bannerList="bannerList" v-if="bannerList.length" />
      <div class="notice notice-bg notice-type1 flex js-sp align-center">
        <img src="@/assets/images/home-noticetype1.png" alt />
        <div class="center notice-t1" v-if="noticeList.length > 0">
          <el-carousel
            height="15px"
            direction="vertical"
            autoplay
            indicator-position="none"
          >
            <el-carousel-item
              class="point"
              v-for="item in noticeList"
              :key="item.id"
              @click="noticeDetail(item.id)"
            >
              {{ item.notice_title }}
            </el-carousel-item>
          </el-carousel>
        </div>
        <router-link to="/announce" class="more notice-t2 text-14 point">
          {{ $t("global.more") }}
        </router-link>
        <i class="el-icon-arrow-right notice-t2"></i>
      </div>
    </div> -->
    <div class="temp-2" ref="headBanner">
      <div class="welcome ">
        <Welcome-one diy-class="top-pd" />
      </div>
    </div>

    <div>
      <!-- 立即注册类型2 -->
      <div class="regist-now black-bg-img">
        <div class=" share-color text-30 mg-bt-md">
          {{ $t("foreign.headerTip1") }}
        </div>
        <div class="share-color text-14">
          {{ $t("foreign.headerTip2") }}
        </div>
      </div>
      <!-- 购买 -->
      <!-- <Buymodel v-if="isBuy" /> -->
      <!-- 推荐交易对 -->
      <div class="share-bg type1-p" style="border-top: 1px solid #333333;">
        <div class="text-left text-30 mb-30 share-color">
          {{ $t("foreign.market") }}
        </div>
        <Currency :changeData="changeData" styleItem="width: 100%;" />
      </div>
    </div>
    <div
      class="teble mg-bt-120"
      :class="
        metaverse ? ' type1-p type1-height' : ' type1-p type1-height-flase'
      "
    >
      <div class="text-left text-30 mb-30 share-color">
        {{ $t("foreign.stock") }}
      </div>
      <!-- 交易对tab -->
      <div class="flex align-center js-st tab-box">
        <div
          class="tab text-triangle point"
          :class="active === 1 ? 'borderLight-bg' : ''"
          @click="changeTradingType(1)"
        >
          {{ $t("stock.USstocks") }}
        </div>
        <div
          class="tab ml-12 text-triangle point"
          :class="active === 2 ? 'borderLight-bg' : ''"
          @click="changeTradingType(2)"
        >
          {{ $t("stock.HKstocks") }}
        </div>
        <div
          class="tab ml-12 text-triangle point"
          :class="active === 3 ? 'borderLight-bg' : ''"
          @click="changeTradingType(3)"
        >
          {{ $t("stock.newEnergy") }}
        </div>
      </div>
      <div class="type-table" :class="{ 'table-type2': type === 2 }">
        <el-table
          :data="tableData"
          v-loading="loading"
          id="table"
          :row-class-name="rowheight"
          :header-row-class-name="headerHeight"
          :header-cell-style="{ 'text-align': 'center', 'font-weight': '400' }"
          :cell-style="{ 'text-align': 'center' }"
          cell-class-name="share-bg"
          header-cell-class-name="share-bg"
          class="customer-table text-18 point share-bg"
          style="width: 100%"
          max-height="555"
        >
          <el-table-column prop="date" :label="$t('exchange.name')">
            <template v-slot="r">
              <div class="family-d text-18 text-bold">
                <span class="text-t1">{{ r.row.stock_name }}|</span>
                <span class="text-t2">{{ r.row.stock_code }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('exchange.closePrice')">
            <template v-slot="r">
              <div class="family-d text-18 text-t1">
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.close_price,
                      r.row.stock_currency_accuracy
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" :label="$t('foreign.changes')">
            <template v-slot="r">
              <div
                class="family-d text-18 text-bolder is-ltr"
                :class="r.row.increase > 0 ? 'text-red' : 'text-green'"
              >
                {{ decimalDigitsInputFilter(r.row.increase * 100, 2) }}%
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="transaction_amount"
            :label="$t('foreign.timeSharingChart')"
          >
            <template #default="r">
              <div class="flex align-center js-ct">
                <stock-kline-pic
                  :isRise="r.row.increase >= 0 ? true : false"
                  :tradingPair="r.row.id"
                  v-if="r.row.id"
                ></stock-kline-pic>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" :label="$t('exchange.trans')">
            <template #default="r">
              <span class="personlink mr-16 point" @click="rowClick(r.row)">
                {{ $t("assets.toTrade") }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div
      class="teble mg-bt-120"
      :class="
        metaverse ? ' type1-p type1-height' : ' type1-p type1-height-flase'
      "
    >
      <div class="text-left text-30 mb-30 share-color">
        {{ $t("foreign.forex") }}
      </div>
      <div class="type-table" :class="{ 'table-type2': type === 2 }">
        <el-table
          v-loading="loadingPair"
          :data="pairTableData"
          id="table"
          :row-class-name="rowheight"
          :header-row-class-name="headerHeight"
          :header-cell-style="{ 'text-align': 'center', 'font-weight': '400' }"
          :cell-style="{ 'text-align': 'center' }"
          cell-class-name="share-bg"
          header-cell-class-name="share-bg"
          class="customer-table text-18 point share-bg"
          style="width: 100%"
          max-height="555"
          @row-click="rowForeignClick"
        >
          <el-table-column prop="date" :label="$t('foreign.newProduct')">
            <template v-slot="r">
              <div class="family-d text-18 text-bold">
                <span class="share-color">{{ r.row.base_currency_name }}/</span>
                <span class="share-color">{{
                  r.row.transaction_currency_name
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('exchange.price')">
            <template v-slot="r">
              <div class="family-d text-18 share-color">
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.current_price,
                      r.row.price_decimal_places
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" :label="$t('foreign.changes')">
            <template v-slot="r">
              <div
                class="family-d text-18 text-bolder is-ltr"
                :class="r.row.intraday_gain > 0 ? 'text-red' : 'text-green'"
              >
                {{ decimalDigitsInputFilter(r.row.intraday_gain * 100, 2) }}%
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="transaction_amount" :label="'涨跌'">
            <template #default="r">
              <div
                class="family-d text-18 "
                :class="r.row.intraday_gain > 0 ? 'text-red' : 'text-green'"
              >
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.bid_price - r.row.ask_price,
                      r.row.price_decimal_places
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="bid_price" :label="'Bid'">
            <template #default="r">
              <div class="family-d text-18 share-color">
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.bid_price,
                      r.row.price_decimal_places
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="transaction_amount" :label="'Ask'">
            <template #default="r">
              <div class="family-d text-18 share-color">
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.ask_price,
                      r.row.price_decimal_places
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="transaction_amount"
            :label="$t('deal.highest')"
          >
            <template #default="r">
              <div class="family-d text-18 share-color">
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.high_price,
                      r.row.price_decimal_places
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="transaction_amount" :label="$t('deal.lowest')">
            <template #default="r">
              <div class="family-d text-18 share-color">
                {{
                  $currency(
                    decimalDigitsInputFilter(
                      r.row.low_price,
                      r.row.price_decimal_places
                    )
                  )
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="parent trad-bg text-left flex ">
      <div class="trad-info share-color flex flex-column js-ct">
        <div class=" text-30 mg-bt-md">{{ $t("foreign.tip") }}</div>
        <div class="text-14">
          {{ $t("foreign.tips", { appName: appName }) }}
        </div>
      </div>
      <img src="@/assets/images/theme_bg_right.png" alt="" class="right-img" />
    </div>
    <!-- 元宇宙资讯 -->
    <div v-if="!isclose" class="pd-tp-120">
      <div class="mb-30 type1-p text-left">
        <div class="share-color text-30 mg-bt-md">
          {{ $t("universe.metaMsg") }}
        </div>
        <div class="text-14 share-color">{{ $t("foreign.learnMore") }}</div>
      </div>
      <MetaverseInfo
        styleItem="width: 100%;padding-inline-start: 65px;"
        :type="'home'"
        @topath="toPathMetaDetail"
        @isclose="handleIsclose"
      />
    </div>
    <!-- 我们的平台 -->
    <!-- <div
      class="pd-100 item bg-ff flex flex-column js-ct align-center type1-p"
      v-if="platform_video_link || platform_cover_image"
    >
      <div class="mb-40 color_1e text-30 text-bolder">
        {{ $t("newTheme.ourApp") }}
      </div>
      <div class="parent w-100 player" v-if="showVideo">
        <youtube-player
          v-if="platform_video_link"
          :id="'our'"
          :height="630"
          :src="platform_video_link"
          ref="youtube"
          :image="platform_cover_image"
          :showPlay="showPlay"
          @playVideo="playVideo"
          @reloadPlay="reloadPlay"
        ></youtube-player>
        <div
          v-if="!platform_video_link && platform_cover_image"
          :style="`background-image: url(${platform_cover_image}) `"
          class="w-100 player-img player"
        ></div>
      </div>
    </div> -->
    <!-- websocket -->
    <!-- <Websocket :timeout="3000" name="涨幅" socket_type="RANKING" @change="onMessage" /> -->
    <!-- 客服悬浮图标 -->
    <div
      class="server-box flex align-center js-ct"
      @click="toPath"
      v-if="service_url === 'contact'"
    >
      <img
        src="@/assets/images/serverbox.png"
        alt=""
        srcset=""
        class="server-icon"
      />
    </div>
    <!-- 新增的第三方客服 -->
    <!-- <div
      class="server-box flex align-center js-ct"
      v-if="service_url === 'online'"
    >
      <a href="#" onclick="psOpenWindow(); return false;"
        ><img
          src="@/assets/images/serverbox.png"
          alt=""
          srcset=""
          class="server-icon"
      /></a>
    </div> -->
    <!-- 客服弹窗 -->
    <ServerBox
      @oncancer="dialog = false"
      @onconfirm="dialog = false"
      :dialog="dialog"
    />
    <!-- 法币交易三方 -->
    <ThirdFiatBox
      @oncancer="thirDialog = false"
      @onconfirm="thirDialog = false"
      :dialog="thirDialog"
    ></ThirdFiatBox>
    <div class="box">
      <el-dialog
        v-model="dialogVisible"
        v-if="dialogParam?.content_type == 1"
        :close-on-click-modal="false"
        center
        top="210px"
        width="400px"
      >
        <div class="dialog-box">
          <img src="@/assets/dialog.png" alt="" srcset="" class="dialog-img" />
          <div v-html="dialogParam.content_text" class="dialog-desc"></div>
          <div
            class="letter-btn share-color text-16 text-bold login-btn text-center point"
            @click="toDialogPath"
          >
            {{ $t("global.tryNow") }}
          </div>
        </div>
      </el-dialog>
      <div class="img-box">
        <el-dialog
          v-model="dialogVisible"
          center
          :show-close="false"
          top="210px"
          width="400px"
          v-if="dialogParam?.content_type == 2"
        >
          <img
            :src="dialogParam.source_url"
            alt=""
            srcset=""
            class="dialog-img"
            @click="toDialogPath"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeOne from "@/components/homeModel/welcomeOne";
// import Buymodel from "@/components/homeModel/buy";
import Currency from "@/components/homeModel/currency";
import StockKlinePic from "@/components/homeModel/stockKlinePic";
import MetaverseInfo from "@/components/universe/metaverseInfo";
// import SwiperBanner from "@/components/homeModel/swiper-banner";
import ServerBox from "@/components/homeModel/serverBox";
import { contentBanner, contentNotice } from "@/api/article";
import { stockPage } from "@/api/stock";
import { getCurrencyPairList } from "@/api/foreign-exchange";
import { getHomeWindowsStatus } from "@/api/system";
// import Websocket from '@/components/websocket/WebSocket'
import { decimalDigitsInputFilter } from "@/utils/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getCurrentInstance } from "vue";
import jumpChat from "@/commonapi/chat";
import getMetaverse from "@/commonapi/metaverse";
import getNFT from "@/commonapi/nft";
import { addFix } from "@/utils/precisionMath.js";
import ThirdFiatBox from "@/components/homeModel/thirdFiatBox";

export default {
  components: {
    WelcomeOne,
    // Buymodel,
    Currency,
    ServerBox,
    MetaverseInfo,
    [StockKlinePic.name]: StockKlinePic,
    ThirdFiatBox,
    // Websocket,
    // SwiperBanner,
  },
  setup() {
    const { chat } = jumpChat();
    return {
      chat,
    };
  },
  data() {
    return {
      timer: null,
      decimalDigitsInputFilter,
      type: 1, //模板
      bannerList: [],
      loading: false,
      dialog: false,
      thirDialog: false,
      tableData: [], //交易对列表
      noticeList: [], //公告列表
      changeData: {}, //涨幅榜数据变动
      $websocketTool: {},
      isBuy: true,
      param: { type: 1, limit: 10, page: 0, stock_type: 1 },
      appName: process.env.VUE_APP_NAME,
      active: 1, //股票tab
      service_url: "", //判断客服跳转类型
      currency_transaction_status: true, //币币交易
      dialogVisible: false,
      dialogParam: {},
      defi: false,
      wealth: false,
      level: false,
      secondLever: false,
      isclose: false,
      pairTableData: [], //外汇对列表
      loadingPair: false,
    };
  },
  computed: {
    fastExchange() {
      return this.$store.state.user.fastExchange;
    },
    systemInfo() {
      return this.$store.state.user.systemInfo;
    },
    metaverse() {
      return this.$store.state.exchange.metaverseStatus;
    },
    NFTtype() {
      return this.$store.state.exchange.NFTStatus;
    },
    local() {
      return this.$store.state.user.local;
    },
  },
  watch: {
    systemInfo: {
      handler() {
        this.getmodel();
      },
    },
  },
  created() {
    const {
      appContext: {
        app: {
          config: { globalProperties },
        },
      },
    } = getCurrentInstance();
    this.websocketTool = globalProperties;
    this.$store = useStore();
    this.$router = useRouter();

    this.getNocice();
    this.getmodel();
  },
  mounted() {
    const { metaverseStatus } = getMetaverse();
    metaverseStatus();
    const { NFTStatus } = getNFT();
    NFTStatus();
    this.getTrading();
    this.handleGetCurrencyPairList();
    this.getHomeDialogStatus();
    this.timer = setInterval(() => {
      if (this.$websocketTool?.ws && this.$websocketTool?.ws?.readyState == 1) {
        clearInterval(this.timer);
        this.timer = null;
        this.handleMsg();
      }
    }, 3000);
    this.getBanner();
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.closeMsg({
      socket_type: "RANKING",
      subscribed: 0,
    });
    this.closeMsg({
      socket_type: "FOREIGN_RANKING",
      subscribed: 0,
    });
    this.closeMsg({
      socket_type: "STOCKRANKING",
      subscribed: 0,
    });
  },
  methods: {
    handleMsg() {
      this.$websocketTool?.ws.send(
        JSON.stringify({
          socket_type: "RANKING",
          subscribed: 1,
        })
      );
      this.$websocketTool?.ws.send(
        JSON.stringify({
          socket_type: "FOREIGN_RANKING",
          subscribed: 1,
        })
      );
      this.$websocketTool?.ws.send(
        JSON.stringify({
          socket_type: "STOCKRANKING",
          subscribed: 1,
        })
      );
      let that = this;
      this.$websocketTool.ws.onmessage = function(res) {
        const message = JSON.parse(res.data);
        if (message.type === "RANKING") {
          that.onMessage(message);
        }
        if (message.type === "FOREIGN_RANKING") {
          that.onForeingMessage(message);
        }
        if (message.type === "STOCKRANKING") {
          that.onStockMessage(message);
        }
      };
    },
    closeMsg(msg) {
      if (this.$websocketTool?.ws && this.$websocketTool?.ws?.readyState == 1) {
        this.$websocketTool?.ws.send(JSON.stringify(msg));
      }
    },
    // 点击去交易
    rowClick(row) {
      // if (!row.transaction_status) {
      //   return false;
      // }
      this.$store.commit("exchange/SET_STOCK_ID", row.id);
      this.$router.push({ path: "/Stock" });
    },
    rowForeignClick(row) {
      this.$store.commit("exchange/SET_FOREIGN", row);
      this.$router.push({ path: "/foreignExchange" });
    },
    // 获取轮播图
    getBanner() {
      this.loading = true;
      let that = this;
      contentBanner({
        application_type: "PC",
        banner_page: 1,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.length > 0) {
            that.bannerList = res.data;
            that.$nextTick(() => {
              that.$refs.headBanner.style.backgroundImage =
                "url(" + that.bannerList[0]?.source_url + ")";
              console.log(that.$refs.headBanner.style.backgroundImage);
            });
          }
        }
      });
    },
    // 获取公告列表
    getNocice() {
      contentNotice().then((res) => {
        this.noticeList = res.data.items;
      });
    },
    // 获取模板
    getmodel() {
      this.systemInfo?.forEach((ele) => {
        if (ele.key === "template_pc") {
          this.type = ele.value === "2" ? 2 : 1;
        }
        if (ele.key === "easy_buy") {
          const show = ele.value ? true : false;
          this.isBuy = show;
        }
        if (ele.key === "currency_transaction_status") {
          this.currency_transaction_status = ele.value;
        }
        if (ele.key === "service_url") {
          const show = ele.value; //online-在线客服页；contact-社交客服页
          this.service_url = show;
          this.dialog = false;
          this.chat();
        }
      });
    },
    changeTradingType(type) {
      this.active = type;
      this.$store.commit("user/SET_TRADING_TAB", {
        type: "stock",
        active: type + 1,
      });
      this.tableData = [];
      this.getTrading();
    },
    //获取外汇列表
    handleGetCurrencyPairList() {
      let params = {
        page: 0,
        limit: 10,
      };
      this.loadingPair = true;
      getCurrencyPairList(params).then((res) => {
        if (res.data && res.data.length > 0) {
          let list = res.data;
          list.forEach((pair) => {
            pair.high_price = pair.low_price + pair.bid_ask_spread;
          });
          this.pairTableData = list.slice(0, 10);
          this.loadingPair = false;
        }
      });
    },
    // 获取股票
    getTrading() {
      this.loading = true;
      this.param.stock_type = this.active;
      stockPage(this.param)
        .then((res) => {
          this.tableData = res.data?.items;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getHomeDialogStatus() {
      getHomeWindowsStatus().then((res) => {
        if (!res.data) {
          return false;
        }
        this.dialogParam = res.data;
        //没弹过弹窗或者弹窗内容id变过或者弹窗是每次都要弹的或者激活的时间不一致
        this.dialogVisible =
          !this.$store.state.user.activeParam.showStatus ||
          res.data.id != this.$store.state.user.activeParam.id ||
          res.data.windows_times === 2 ||
          res.data.activation_time !=
            this.$store.state.user.activeParam.activation_time;
        let obj = {
          activation_time: res.data.activation_time, //激活时间
          id: res.data.id, //内容id
          showStatus: true, //是否弹出过
        };
        this.$store.commit("user/SET_ACTIVEPARAM", obj);
        this.systemInfo?.forEach((ele) => {
          if (ele.key === "defi_ore_status") {
            this.defi = ele.value;
          }
          if (ele.key === "wealth_management_status") {
            this.wealth = ele.value;
          }
          if (ele.key === "lever_status") {
            this.lever = ele.value;
          }
          if (ele.key === "second_lever_status") {
            this.secondLever = ele.value;
          }
        });
      });
    },
    //点击公告跳转详情
    noticeDetail(id) {
      this.$router.push({ path: "/detail", query: { id: id, type: 1 } });
    },
    // 点击轮播图跳转链接
    jump(url) {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    //判断客服跳转
    toPath() {
      if (this.service_url === "contact") {
        this.dialog = true;
      }
    },
    //首页弹窗点击跳转
    toDialogPath() {
      if (this.dialogParam.skip_type === 1) {
        if (this.dialogParam.skip_url) {
          window.open(this.dialogParam.skip_url);
        }
      } else {
        if (this.dialogParam.skip_url) {
          if (this.dialogParam.skip_url === "defi" && this.defi) {
            this.$router.push("/defiFinancial");
          }
          if (this.dialogParam.skip_url === "wealth" && this.wealth) {
            this.$router.push("/wealthFinancial");
          }
          if (this.dialogParam.skip_url === "lever" && this.lever) {
            this.$router.push("/lever");
          }
          if (this.dialogParam.skip_url === "secondLever" && this.secondLever) {
            this.$router.push("/secondlever");
          }
          if (
            this.dialogParam.skip_url === "fastExchange" &&
            this.fastExchange
          ) {
            this.$router.push("/flashAgainst");
          }
        }
      }
    },
    toPathMetaDetail(val) {
      this.$router.push(`/universeDetail?id=${val.id}&type=${val.type}`);
    },
    handleIsclose(val) {
      this.isclose = val;
    },
    // 表格行高度
    rowheight: function() {
      return "table-rowheight";
    },
    headerHeight() {
      return "table-header";
    },
    // websocket推送数据
    onMessage(val) {
      // let data = [...this.tableData];
      // data.forEach((item) => {
      //   if (item.id === val.currencyMatchId) {
      //     item.increase = val.increaseData.increaseValue;
      //     item.close_price = val.increaseData.nowPrice;
      //     item.transaction_amount = val.increaseData.transactionAmount;
      //   }
      // });
      this.changeData = val;
      // this.tableData = data;
    },
    onForeingMessage(val) {
      let data = [...this.pairTableData];
      data.forEach((item) => {
        if (item.id === val.currency_pair_id) {
          item.intraday_gain = val.increase_data.increase_value;
          item.ask_price = val.increase_data.now_price;
          item.bid_price = addFix(
            val.increase_data.now_price,
            item.bid_ask_spread
          );
          item.high_price = val.increase_data.high_price;
          item.low_price = val.increase_data.low_price;
          item.current_price = val.increase_data.now_price;
        }
      });
      this.pairTableData = [...data];
    },
    onStockMessage(val) {
      let data = [...this.tableData];
      data.forEach((item) => {
        if (item.id === val.currencyMatchId) {
          item.increase = val.increaseData.increaseValue;
          item.close_price = val.increaseData.nowPrice;
        }
      });
      this.tableData = data;
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 820px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
// 模板二
.temp-2 {
  width: 100%;
  height: 763px;
  position: relative;
  background-image: url("../assets/banner.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.welcome {
  position: absolute;
  left: 0;
  top: 130px;
  z-index: 100;
}
.temp2-notice {
  width: 100%;
  height: 100px;
}
.notice-type2 {
  width: 377px;
  padding: 0 4px;
  border-radius: 99px;
  padding-inline-end: 10px;
  background: #f1f1f1;
  color: #7a7a7a;
  height: 42px;
  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }
}
.notice {
  width: 377px;
  border-radius: 99px;
  padding-inline-end: 10px;
  margin: 0 auto;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.notice-type1 {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 99;
}
.curency {
  width: 100%;
  padding: 0 155px;
  border-top: 1px solid #333333;
}
.is-rtl {
  .center {
    border-right: none;
    border-left: 1px solid #989898;
  }
}
.center {
  width: 300px;
  border-right: 1px solid #989898;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.more {
  width: 76px;
  text-align: center;
}
.teble {
  width: 100%;
  border-radius: 10px;
  margin: 0 auto;
}
.type1-height {
  max-height: 775px;
}
.type1-height-false {
  max-height: 647px;
}
.type2-height {
  max-height: 688px;
}
.type2-height-false {
  max-height: 560px;
}
.type-table {
  border-radius: 10px;
  border: 1px solid #323232;
  overflow: hidden;
}
.type-table {
  :deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
    background-color: #1e1e1e;
  }
  :deep(.el-table td),
  :deep(.el-table th.is-leaf) {
    border-color: #323232;
  }
}
.type1-p {
  padding: 26px 155px 0;
}
.top-pd {
  padding-inline-start: 155px;
}
.type2-p {
  padding: 0 40px;
}
.table-type2 {
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
}
.tab {
  border-radius: 8px;
  padding: 6px 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.tab-active {
  background: #eef0f2;
}
.ml-12 {
  margin-inline-start: 12px;
}
.tab-box {
  margin-top: 34px;
  margin-bottom: 30px;
}
.regist-now {
  width: 100%;
  height: 286px;
  padding-top: 90px;
  box-sizing: border-box;
}
.regist-title {
  margin-bottom: 60px;
}
.regist-btn {
  width: 380px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 6px;
  margin: 0 auto;
  display: block;
}
.bg-img {
  background-image: url("../assets/regist-bg.png");
  background-size: 100% 100%;
}
.black-bg-img {
  background-image: url("../assets/regist-bg-black.png");
  background-size: 100% 100%;
}
.home {
  position: relative;
}
.server-box {
  position: fixed;
  right: 60px;
  bottom: 60px;
  background: rgba(55, 113, 226, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 14px rgba(55, 113, 226, 0.3));
  z-index: 1999;
}
.server-icon {
  width: 67.5px;
  height: 67.5px;
}
.scroll-banner {
  width: 100%;
  height: 140px;
  padding: 0 40px;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.el-table__row .table-rowheight:last-child td {
  border-bottom: 1px solid #fff;
}
.bg-fff {
  background-color: #fff;
}
.box /deep/.el-dialog__header {
  border-bottom: none;
  padding: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.text-semibold {
  font-weight: 600;
}
.dialog-box {
  padding: 15px 15px 0px;
  .dialog-img {
    width: 100%;
    height: 154px;
    display: block;
    margin-bottom: 24px;
  }
  .dialog-desc {
    margin-bottom: 24px;
  }
  .letter-btn {
    width: 100%;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
  }
}
.img-box /deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .dialog-img {
    margin-bottom: 0px;
    width: 100%;
    height: auto;
    display: block;
  }
}
.trad-bg {
  background-image: url("../assets/images/themebg1.png");
  background-size: 100% 600px;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  box-sizing: border-box;
  .trad-info {
    width: 70%;
    padding-inline-start: 11%;
  }
  .right-img {
    position: absolute;
    top: 72px;
    right: 113px;
    width: 487px;
    display: block;
  }
}
.is-rtl {
  .trad-bg {
    .right-img {
      position: absolute;
      top: 72px;
      left: 113px;
      right: auto;
      width: 487px;
      display: block;
    }
  }
}
.pt-60 {
  padding-top: 60px;
}
.mb-30 {
  margin-bottom: 30px;
}
.quote-box {
  width: 1120px;
  margin: 0 auto;
}
.mg-bt-120 {
  margin-bottom: 120px;
}
.thirdbox {
  :deep(.el-dialog__body) {
    padding: 10px 30px 40px 30px;
  }
  :deep(.el-dialog__header) {
    border-bottom: none;
  }
}
.pd-tp-120 {
  padding-top: 120px;
}
</style>
<style>
.home .el-table .el-table__row:last-child td {
  border: none;
}
/* .home .swiper-button-disabled {
  display: none;
} */
</style>
