import { useStore } from "vuex";
import { computed } from "vue";
export default function jumpChat() {
  const $store = useStore();
  const service_url = computed(() => {
    const systemInfo = $store.state.user.systemInfo;
    return systemInfo.find((item) => item.key === "service_url");
  });

  const chat = () => {
    if (process.env.VUE_APP_C_SERVICE === "1") {
      console.log(service_url.value?.value);
      // if (service_url.value?.value === "online") {
      //   //旧接口
      //   zE(function() {
      //     zE.show();
      //   });
      //   //更新的接口(要先去exchange_pc\public\index.html打开Zendesk的脚本注释)
      //   window.zE("messenger", "show");
      // } else {
      //   // 在线客服打开注释
      //   zE(function() {
      //     zE.hide();
      //   });
      // }
      // liveChat插件开始************************
      if (service_url.value?.value === "online") {
        window.LiveChatWidget.call("minimize");
      } else {
        window.LiveChatWidget.call("hide");
      }
      // liveChat插件结束************************
    }
  };
  const chatActive = () => {
    // liveChat插件开始************************
    window.LiveChatWidget.call("maximize");
    // liveChat插件结束************************
    // zE.activate()
  };
  return {
    chat,
    chatActive,
  };
}
